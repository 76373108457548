<template>
  <y-sheet>

    <template #title>
      {{ season.name }}
    </template>

    <y-input v-model="seasonName">
      Nom de la saison
    </y-input>

    <div class="grid gap-2 grid-cols-1 md:grid-cols-2">
      <div>
        <y-date-picker v-model="seasonStart">
          Début de la saison
        </y-date-picker>
      </div>
      <div>
        <y-date-picker v-model="seasonEnd">
          Fin de la saison
        </y-date-picker>
      </div>
    </div>

    <template #footer>
      <button
         :class="{
          'text-sm text-white px-4 py-3 w-full rounded focus:outline-none uppercase transition': true,
          'bg-gray-600 cursor-not-allowed': !canSubmit,
          'bg-green-500 hover:bg-green-600': canSubmit,
        }"
        @click="createNewSeason">
        Modifier
      </button>
    </template>

  </y-sheet>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { getTimeStampStartOfTheDay, getTimeStampEndOfTheDay } from '@/classes/Tools';

import YSheet from '@/UI/YSheet.vue';
import YInput from '@/UI/YInput.vue';
import YDatePicker from '@/UI/YDatePicker.vue';

export default {

  components: {
    YSheet,
    YInput,
    YDatePicker,
  },

  props: {
    modalRef: {
      type: String,
      required: true,
    },
    eventId: {
      type: String,
      required: false,
    },
    season: {
      type: Object,
      required: true,
    },
    seasonId: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const store = useStore();
    const { $emitter } = getCurrentInstance().appContext.config.globalProperties;

    const seasonName = ref(props.season.name);
    const seasonStart = ref(props.season.from);
    const seasonEnd = ref(props.season.to);

    const canSubmit = computed(() => props.eventId
      && seasonName.value
      && seasonStart.value
      && seasonEnd.value
      && seasonStart.value < seasonEnd.value);

    const createNewSeason = () => {
      const { eventId, seasonId } = props;
      const season = {
        ...props.season,
        name: seasonName.value,
        from: getTimeStampStartOfTheDay(seasonStart.value),
        to: getTimeStampEndOfTheDay(seasonEnd.value),
      };
      store
        .dispatch('updateSeasonForEvent', { season, eventId, seasonId })
        .then(() => {
          $emitter.emit('modal:close', props.modalRef);
        });
    };

    return {
      seasonName,
      seasonStart,
      seasonEnd,
      canSubmit,
      createNewSeason,
    };
  },

};
</script>

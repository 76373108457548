<template>

  <y-card-subtitle>
    Saisons
  </y-card-subtitle>

  <ul
    v-if="getEvent.seasonality"
    class="divide-y">
    <li
      v-for="season in getSeasons"
      :key="season.id"
      class="px-4 py-3 flex items-center justify-between">
      <div>
        {{ season.name }}
        <div class="text-xs font-light text-gray-400">
          du
          <span class="font-medium">{{ convertTimeStampToShortFormatedDate(season.from) }}</span>
          au
          <span class="font-medium">{{ convertTimeStampToShortFormatedDate(season.to) }}</span>
        </div>
      </div>
      <button
        class="bg-white hover:bg-gray-100 transition p-2 rounded-full text-blue-500 focus:outline-none"
        @click="editSeason(season)">
        <y-icon-pencil class="h-5" />
      </button>
    </li>
  </ul>

</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { convertTimeStampToShortFormatedDate, sortFirebaseObjectBy } from '@/classes/Tools';

import YCardSubtitle from '@/UI/YCardSubtitle.vue';
import YIconPencil from '@/UI/icons/YIconPencil.vue';

import SeasonEditorModal from '@/modals/SeasonEditorModal.vue';

export default {

  components: {
    YCardSubtitle,
    YIconPencil,
  },

  setup() {
    const { $emitter } = getCurrentInstance().appContext.config.globalProperties;
    const store = useStore();
    const getEvent = computed(() => store.getters.getEvent);
    const getSeasons = computed(() => sortFirebaseObjectBy(getEvent.value.seasons, 'from'));

    const editSeason = (season) => {
      const seasonId = season.id;
      const eventId = getEvent.value.id;
      $emitter.emit('modal:open', {
        component: SeasonEditorModal,
        props: { season, seasonId, eventId },
      });
    };

    return {
      getEvent,
      getSeasons,
      editSeason,
      convertTimeStampToShortFormatedDate,
    };
  },

};
</script>
